<template>
  <div class="" v-if="withholding">
    <TopBarTitleComponent title="Información Retención" :showBackIcon="true">
      <template slot="right-area">
        <div class="d-flex justify-content-end align-items-center">
          <el-dropdown
            @command="handleCommand"
            class="dropdown-theme-gray-outline d-flex align-items-center mr-4"
          >
            <span class="el-dropdown-link">
              <span class="ml-2">Opciones</span>
              <i class="el-icon-arrow-down el-icon--right mr-2"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <template
                v-if="
                  withholding.status === 'draft' ||
                    withholding.status === 'failed'
                "
              >
                <el-dropdown-item command="withholding-edit"
                  ><img :src="iconEdit" alt="" /> Editar</el-dropdown-item
                >
              </template>
              <template v-if="withholding.status === 'success'">
                <el-dropdown-item command="withholding-nuled"
                  ><img :src="iconNuled" alt="" /> Anular
                  retención</el-dropdown-item
                >
              </template>

              <el-dropdown-item
                v-if="
                  withholding.status !== 'success' &&
                    withholding.status !== 'nuled'
                "
                command="withholding-delete"
              >
                <img :src="iconDelete" alt="" /> Eliminar
              </el-dropdown-item>
              <el-dropdown-item command="withholding-send">
                <img :src="iconSend" alt="" /> Enviar
                documento</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
          <ButtonPrimaryComponent
            v-if="
              withholding.status !== 'success' && withholding.status !== 'nuled'
            "
            title="Firmar"
            @onSaveClick="toogleModalWithholdingSRI"
          />
        </div>
      </template>
    </TopBarTitleComponent>
    <b-container fluid class="pb-4 px-lg-4 pt-4">
      <b-row v-if="withholding.sri_sign_message">
        <b-col>
          <AlertInfoComponent
            title="Notificación SRI"
            type="error"
            class="mb-4"
            :description="withholding.sri_sign_message"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="card box-shadow card-quotes-view py-4">
            <div class="card-body">
              <div class="card-quotes-view-header">
                <b-row>
                  <b-col xs="12" sm="12" md="12" lg="6">
                    <div class="card-quotes-view-logo mb-4">
                      <b-row>
                        <b-col xs="12" sm="12" md="5" lg="4">
                          <div class="logo">
                            <img
                              v-if="getUser.user_business.business_logo"
                              :src="getUser.user_business.business_logo"
                              class="image"
                              id="image-business"
                              alt="Empresa"
                              width="200px"
                            />
                          </div>
                        </b-col>
                        <b-col xs="12" sm="12" md="7" lg="8">
                          <div class="info">
                            <h4 class="h4 font-bold m-0">
                              N° {{ withholding.identification_document }}
                            </h4>
                            <h2 class="h2 font-bold m-0"></h2>
                            <p class="font-semi-bold mt-2">
                              RUC: {{ getUser.user_business.business_ruc }}
                            </p>
                            <p class="font-semi-bold">
                              {{ getUser.user_business.business_address }}
                            </p>

                            <SignAuthorizationInfo
                              class="mt-4"
                              :accessKey="withholding.access_key"
                              :signMode="withholding.sign_mode"
                            />
                          </div>
                        </b-col>
                      </b-row>
                    </div>
                  </b-col>
                  <b-col xs="12" sm="12" md="12" lg="6">
                    <div class="card-quotes-view-info">
                      <!-- <div
                        class="header-title-section bg-color-primary py-0 mb-4 border-radius"
                      >
                        <h2 class="h2 font-semi-bold text-center py-2">
                          Cotización
                        </h2>
                      </div> -->
                      <b-row>
                        <b-col xs="12" sm="4" md="4" lg="4">
                          <label class="font-semi-bold d-block"
                            >Fecha de emisión</label
                          >
                          <p class="m-0">
                            {{ withholding.emission_date_for_view }}
                          </p>
                        </b-col>
                        <b-col xs="12" sm="4" md="4" lg="4">
                          <label class="font-semi-bold d-block"
                            >Punto de emisión</label
                          >
                          <p
                            class="m-0"
                            v-if="withholding.user_business_e_point_id"
                          >
                            {{ withholding.emission_point.code }}
                          </p>
                        </b-col>
                        <b-col xs="12" sm="4" md="4" lg="4">
                          <label class="font-semi-bold d-block mb-2"
                            >Status</label
                          >
                          <StatusReadDocumentsComponent
                            :status.sync="withholding.status"
                          />
                        </b-col>
                      </b-row>
                      <b-row class="mt-4">
                        <b-col xs="12" sm="4" md="4" lg="4">
                          <label class="font-semi-bold d-block">Cliente</label>
                          <p class="m-0">{{ withholding.client.name }}</p>
                        </b-col>
                        <b-col xs="12" sm="4" md="4" lg="4">
                          <label class="font-semi-bold d-block"
                            >Documento</label
                          >
                          <p class="m-0">{{ withholding.client.document }}</p>
                        </b-col>
                        <b-col xs="12" sm="4" md="4" lg="4">
                          <label class="font-semi-bold d-block">Correo</label>
                          <p class="m-0">
                            {{ withholding.client.email }}
                          </p>
                        </b-col>
                      </b-row>
                      <b-row class="mt-4">
                        <b-col xs="12" sm="4" md="4" lg="4">
                          <label class="font-semi-bold d-block"
                            >Periodo fiscal</label
                          >
                          <p class="m-0">
                            {{ withholding.fiscal_period }}
                          </p>
                        </b-col>
                      </b-row>
                    </div>
                  </b-col>
                </b-row>
              </div>
              <div class="card-quotes-view-body mt-5">
                <div class="card-quotes-view-body-products">
                  <div class="card-quotes-view-table table-items">
                    <b-row class="table-items-header">
                      <b-col xs="12" sm="12" md="3">
                        <h6 class="h6 font-bold">Descripción</h6>
                      </b-col>
                      <b-col xs="12" sm="12" md="2">
                        <h6 class="h6 font-bold ml-2">Porcentaje</h6>
                      </b-col>
                      <b-col xs="12" sm="12" md="2">
                        <h6 class="h6 font-bold">Base imponible</h6>
                      </b-col>
                      <b-col xs="12" sm="12" md="1">
                        <h6 class="h6 font-bold">Total</h6>
                      </b-col>
                      <b-col xs="12" sm="12" md="4">
                        <h6 class="h6 font-bold">Documento</h6>
                      </b-col>
                    </b-row>
                    <b-row
                      class="mt-2 d-flex align-items-center"
                      v-for="(tax, indexTax) in withholding.details"
                      :key="indexTax"
                    >
                      <b-col xs="12" sm="12" md="3">
                        <p class="m-0 ml-2">
                          <strong>Anexo: {{ tax.annex }}</strong>
                        </p>
                        <p class="ml-2">{{ tax.title }}</p>
                      </b-col>
                      <b-col xs="12" sm="12" md="2">
                        <p class="">{{ `${tax.percentage}%` }}</p>
                      </b-col>
                      <b-col xs="12" sm="12" md="2">
                        <p class="">${{ `${tax.amount}` }}</p>
                      </b-col>
                      <b-col xs="12" sm="12" md="1">
                        <p class="">${{ `${tax.total}` }}</p>
                      </b-col>
                      <b-col xs="12" sm="12" md="4">
                        <p class="m-0">
                          {{
                            `${tax.sri_document_type &&
                              tax.sri_document_type.title} - ${
                              tax.emission_date_for_view
                            }`
                          }}
                        </p>
                        <p class="m-0">
                          {{ `${tax.document_identification}` }}
                        </p>
                      </b-col>
                    </b-row>
                  </div>
                </div>
                <hr />
                <b-row>
                  <b-col xs="12" sm="12" md="6"></b-col>
                  <b-col xs="12" sm="12" md="4" offset-md="2"></b-col>
                </b-row>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>

    <el-dialog
      width="35%"
      title=""
      :visible.sync="withholdingSRIOuterVisible"
      class=""
    >
      <div class="wrapper__invoice-signature">
        <h4 class="h4 text-center font-semi-bold text-break text-color-primary">
          ¿Desea firmar y enviar el documento al SRI?
        </h4>
        <div class="d-flex justify-content-center align-items-center mt-5">
          <button type="button" class="btn md__btn-third-secondary mr-4">
            Cancelar
          </button>
          <ButtonPrimaryComponent
            title="Confirmar"
            @onSaveClick="sendWithholdingSRI"
          />
        </div>
      </div>
    </el-dialog>

    <el-dialog
      width="35%"
      title=""
      :visible.sync="withholdingNuledOuterVisible"
      class=""
    >
      <div class="wrapper__invoice-nuled text-center">
        <img :src="icoNotifyDanger" alt="ico danger" width="30" />
        <h4 class="h4 font-semi-bold text-break text-color-primary mt-4">
          ¿Seguro desea anular la retención?
        </h4>

        <p class="text-color-primary">
          Para completar todo el proceso de anulación la retención debe ser
          anulada en el
          <LinkSRI />
        </p>

        <div class="d-flex justify-content-center align-items-center mt-4">
          <button
            @click="toggleModalWithholdingNuled"
            class="btn md__btn-secondary mr-4"
          >
            Cancelar
          </button>
          <ButtonPrimaryComponent
            title="Anular"
            @onSaveClick="onWithholdingNuled"
          />
        </div>
      </div>
    </el-dialog>

    <el-dialog
      width="35%"
      title=""
      :visible.sync="withholdingDeleteOuterVisible"
      class=""
    >
      <div class="wrapper__invoice-delete text-center">
        <img :src="icoNotifyDanger" alt="ico danger" width="30" />
        <h4 class="h4 font-semi-bold text-break text-color-primary mt-4">
          ¿Seguro desea eliminar la retención?
        </h4>

        <p class="text-color-primary text-break">
          Recuerde que solo puede eliminar las retenciones en borrador.
        </p>

        <div class="d-flex justify-content-center align-items-center mt-4">
          <button
            @click="toggleModalWithholdingDelete"
            class="btn md__btn-secondary mr-4"
          >
            Cancelar
          </button>
          <ButtonPrimaryComponent
            title="Eliminar"
            @onSaveClick="onWithholdingDelete"
          />
        </div>
      </div>
    </el-dialog>

    <WrapperSendDocumentDrawer
      @onDocumentSend="onDocumentSend"
      @onCloseDrawer="onCloseDocumentSendDrawer"
      :visibleDocumentSend="visibleDocumentSend"
      :toMails="toMails"
      :isLoadingConfigTexts="false"
    />
  </div>
</template>

<script>
import AlertInfoComponent from "@/components/Alerts/Info";
import TopBarTitleComponent from "../../../../components/TopBarTitle";
import withholdingService from "../services/withholdingService";
import ButtonPrimaryComponent from "../../../../components/ButtonPrimary";
import StatusReadDocumentsComponent from "../../../../components/StatusReadDocuments";
import SignAuthorizationInfo from "../../../components/SignAuthorizationInfo";
import WrapperSendDocumentDrawer from "@/modules/dashboard/components/WrapperSendDocumentDrawer";
import LinkSRI from "@/components/General/LinkSRI";

import {
  icoNotifyDanger,
  iconSend,
  iconEdit,
  iconDelete,
  iconNuled
} from "@/services/resources";

export default {
  name: "WithholdingViewPage",
  data: () => ({
    iconEdit,
    iconDelete,
    iconNuled,
    iconSend,
    icoNotifyDanger,
    withholdingSRIOuterVisible: false,
    withholdingDeleteOuterVisible: false,
    withholdingNuledOuterVisible: false,
    withholding: null,
    visibleDocumentSend: false
  }),
  computed: {
    getUser() {
      return this.$store.getters.getUser;
    },
    toMails() {
      const { client } = this.withholding;
      if (client && client.email) return [client.email];
      return [];
    }
  },
  methods: {
    goPage(name) {
      this.$router.push({ name });
    },
    toogleModalWithholdingSRI() {
      this.withholdingSRIOuterVisible = !this.withholdingSRIOuterVisible;
    },
    onCloseDocumentSendDrawer() {
      this.visibleDocumentSend = false;
    },
    async sendWithholdingSRI() {
      this.$store.dispatch("toggleRequestLoading", {
        text:
          "Por favor espere un momento, su documento esta siendo enviado al SRI."
      });
      try {
        this.toogleModalWithholdingSRI();
        let response = await withholdingService
          .sendWithholdingSRI({
            user_business_withholding_id: this.withholding.id
          })
          .finally(() => {
            this.$store.dispatch("toggleRequestLoading");
          });
        const { success, message, data } = response.data;
        if (success) {
          this.$notifications.success({
            message
          });
        } else {
          this.$notifications.error({
            message
          });
        }
        this.withholding = data;
      } catch (error) {
        return false;
      }
    },
    async getWithholding(withholdingId) {
      this.$store.dispatch("toggleRequestLoading");
      try {
        let response = await withholdingService
          .getWithholdingId(withholdingId)
          .finally(() => {
            this.$store.dispatch("toggleRequestLoading");
          });
        const { success, data } = response.data;
        if (success) this.withholding = data;
      } catch (error) {
        return false;
      }
    },
    async onWithholdingNuled() {
      try {
        this.$store.dispatch("toggleRequestLoading");
        const { id: withholdingId } = this.withholding;
        let response = await withholdingService
          .updateWithholdingStatus({
            user_business_withholding_id: withholdingId
          })
          .finally(() => {
            this.$store.dispatch("toggleRequestLoading");
            this.toggleModalWithholdingNuled();
          });
        const { success, data, message } = response.data;
        if (success) {
          this.withholding = data;
          this.$notifications.success({
            message
          });
        }
      } catch (error) {
        return false;
      }
    },
    async onWithholdingDelete() {
      try {
        this.$store.dispatch("toggleRequestLoading");
        const { id: withholdingId } = this.withholding;
        let response = await withholdingService
          .deleteWithholdingId(withholdingId)
          .finally(() => {
            this.$store.dispatch("toggleRequestLoading");
            this.toggleModalWithholdingDelete();
          });
        const { success, message } = response.data;
        if (success) {
          this.$notifications.success({
            message
          });
          this.goPage("sales.documents");
        }
      } catch (error) {
        return false;
      }
    },
    async sendInvoiceEmail({ data }) {
      this.$store.dispatch("toggleRequestLoading");
      try {
        let response = await withholdingService
          .sendWithholdingEmail({
            data
          })
          .finally(() => {
            this.$store.dispatch("toggleRequestLoading");
          });
        const { success, message } = response.data;
        if (success) {
          this.visibleDocumentSend = false;
          this.$notifications.success({
            message
          });
        }
      } catch (error) {
        return false;
      }
    },
    onDocumentSend({ data }) {
      data.append("withholding_id", this.withholding.id);

      this.sendInvoiceEmail({ data });
    },
    handleCommand(command) {
      switch (command) {
        case "withholding-nuled":
          this.toggleModalWithholdingNuled();
          break;
        case "withholding-edit":
          this.goPage("sales.withholdings.edit", {
            id: this.withholding.id
          });
          break;
        case "withholding-delete":
          this.toggleModalWithholdingDelete();
          break;
        case "withholding-send":
          this.visibleDocumentSend = true;
          break;
      }
    },
    toggleModalWithholdingNuled() {
      this.withholdingNuledOuterVisible = !this.withholdingNuledOuterVisible;
    },
    toggleModalWithholdingDelete() {
      this.withholdingDeleteOuterVisible = !this.withholdingDeleteOuterVisible;
    }
  },
  components: {
    TopBarTitleComponent,
    AlertInfoComponent,
    ButtonPrimaryComponent,
    StatusReadDocumentsComponent,
    SignAuthorizationInfo,
    WrapperSendDocumentDrawer,
    LinkSRI
  },
  mounted() {
    const { id: withholdingId } = this.$route.params;
    if (withholdingId) {
      this.getWithholding(withholdingId);
    }
  }
};
</script>

<style lang="scss" scoped>
.card-quotes-view {
  .card-quotes-view-header {
    .card-quotes-view-logo {
      p {
        margin: 0;
      }
    }
  }
  .card-quotes-view-body-products {
    min-height: 300px;
  }
}
</style>
